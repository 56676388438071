import { createGlobalStyle } from 'styled-components';
import BlissProRegularTtf from './BlissPro-Regular.ttf';
import BlissProRegularEot from './BlissPro-Regular.eot';
import BlissProRegularWoff from './BlissPro-Regular.woff';
import SourceSansProSvg from './SourceSansPro-Regular.svg';
import SourceSansProEot from './SourceSansPro-Regular.eot';
import SourceSansProTtf from './SourceSansPro-Regular.ttf';
import SourceSansProWoff from './SourceSansPro-Regular.woff';
import SourceSansProWoff2 from './SourceSansPro-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'BlissPro';
        src: local('BlissPro'),
        url(${BlissProRegularTtf}) format('truetype'),
        url(${BlissProRegularEot}),
        url(${BlissProRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SourceSansPro';
        src: local('SourceSansPro'),
        url(${SourceSansProTtf}) format('truetype'),
        url(${SourceSansProEot}),
        url(${SourceSansProSvg}),
        url(${SourceSansProWoff}) format('woff'),
        url(${SourceSansProWoff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
`;

export const SMALL = '0.75em';
export const MEDIUM = '0.875em';
export const XMEDIUM = '1em';
export const LARGE = '1.25em';
export const XLARGE = '1.5em';
export const XXLARGE = '2.18em';
export const XXXLARGE = '2.875em';

export const BlissPro = 'BlissPro';
export const SourceSansPro = 'SourceSansPro';
