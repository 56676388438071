/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import GlobalFonts from './components/abstracts/fonts';
import { configureEnvironment } from './env';

const CommonWrapper: React.FC = (props) => (
  <div>
    <GlobalFonts />
    {props.children}
  </div>
);

void configureEnvironment()
  .then(async () => {
    const Root = (await import('./Root')).default;
    const { setupDB } = await import('./service/upload/indexedDb');
    setupDB();
    const { setRedirectURL } = await import('./util/urlRedirect');
    const currentURL = window.location.pathname + window.location.search + window.location.hash;
    setRedirectURL(currentURL);
    return <Root />;
  })
  .catch(() => {
    return <div>Application failed to load</div>;
  })
  .then((it) => {
    ReactDOM.render(<CommonWrapper>{it}</CommonWrapper>, document.getElementById('root'));
  });
